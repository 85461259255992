.header {
  @apply flex items-center bg-gray-100 w-screen shadow border border-solid border-gray-200;

  .wrapper {
    @apply relative container mx-auto flex flex-row items-center justify-between;

    .logo {
      @apply w-10 h-10 p-[2px] md:w-60 md:h-fit rounded-full md:rounded;

      img {
        @apply w-full h-full object-cover object-left;
      }
    }
  }
}
