@tailwind base;
@tailwind components;
@tailwind utilities;

.main-app {
  @apply flex flex-col h-full gap-0 sm:gap-4 bg-gray-100 min-h-screen;

  .header {
    @apply px-4 h-14 md:h-24;
  }

  .footer {
    @apply bg-gray-700 h-14;
  }

  .content {
    @apply container mx-auto;
    flex: 1;
  }

  .page-wrapper {
    .page-content {
      @apply rounded p-2 md:p-4;

      &.with-bg {
        @apply bg-gray-50;
      }

      .change-password-container {
        input[type="text"],
        input[type="number"],
        input[type="password"] {
          @apply px-4 py-2 text-xl font-normal border border-solid border-gray-300 rounded transition ease-in-out duration-100;

          &::placeholder {
            color: #8f90a6;
          }
          &:hover {
            @apply border-gray-400;
          }
          &:focus {
            @apply border-blue-700;
          }

          &.is-valid {
            border-color: #27c24c !important;
          }
          &.is-invalid {
            border-color: #f05050 !important;
          }
        }

        textarea {
          @apply p-1 text-sm leading-4 tracking-tight font-normal border border-solid border-gray-300 rounded transition ease-in-out duration-100;

          &::placeholder {
            color: #8f90a6;
          }
          &:hover {
            @apply border-gray-400;
          }
          &:focus {
            @apply border-blue-700;
          }

          &.is-valid {
            border-color: #27c24c !important;
          }
          &.is-invalid {
            border-color: #f05050 !important;
          }
        }
      }
    }

    .form-control {
      @apply flex flex-col justify-end;
    }

    .box-border-rounded {
      @apply rounded p-2 xl:p-4 border border-solid border-gray-200;
    }

    .link {
      @apply text-blue-500;

      &:hover {
        @apply underline;
      }
    }
  }
}
